<template>
    <div class="review-item">
        <div style="margin-left: auto; margin-right: auto;">
            <b-icon-star-fill v-for="i in [1,2,3,4,5]" v-bind:key="i"></b-icon-star-fill>
        </div>
        <p style="white-space: pre-line;">&quot;{{review.description}}&quot;</p>
        <p class="author">- {{review.author}}</p>
        <div style="width: 100%; text-align: right;">
            <div class="separator"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReviewItem",
    props: {
        review: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style scoped>
div.separator {
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 20px;
    width: 100px;
    height: 1px;
    background-color: white;
}
</style>
