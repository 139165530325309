export default [
    ['Greater Manchester',  380],
    ['Cheshire',  380],
    ['Merseyside',  380],
    ['Lancashire',  380],
    ['Cumbria',  400],
    ['West Yorkshire',  390],
    ['South Yorkshire',  410],
    ['East Yorkshire',  430],
    ['North Yorkshire',  420],
    ['Derbyshire',  405],
    ['Nottinghamshire',  440],
    ['Leicestershire',  420],
    ['Lincolnshire',  520],
    ['Northamptonshire',  435],
    ['Staffordshire',  380],
    ['Shropshire',  405],
    ['West Midlands',  440],
    ['Worcestershire',  420],
    ['Warwickshire',  430],
    ['Herefordshire',  395],
    ['Wrexham',  380],
    ['Flintshire',  380],
    ['Denbighshire',  385],
    ['Conwy',  395],
    ['Gwynedd',  435],
    ['Anglesey',  425],
    ['Powys',  470],
    ['Ceredigion',  590],
    ['Monmouthshire',  480],
    ['Blaenau Gwent',  515],
    ['Tydfil',  500],
    ['Torfaen',  500],
    ['Newport',  520],
    ['Rhondda Cynon Taf',  545],
    ['Caerphilly',  530],
    ['Neath Port Talbot',  545],
    ['Carmarthenshire',  610],
    ['Cardiff',  535],
    ['Bridgend',  535],
    ['Swansea',  550],
    ['Pembrokeshire', 630],
    ['Newcastle upon Tyne', 525],
    ['Durham',  515],
    ['Northumberland',  535],
    ['Bedfordshire',  450],
    ['Cambridgeshire',  480],
    ['Hertfordshire',  520],
    ['Norfolk', 550],
    ['Suffolk',  535],
    ['Oxfordshire',  450],
    ['Buckinghamshire',  450],
    ['Berkshire',  520],
    ['London',  610],
    ['Essex',  600],
    ['Surrey',  590],
    ['Hampshire',  590],
    ['Kent',  620],
    ['West Sussex',  620],
    ['East Sussex',  630],
    ['Gloucestershire',  450],
    ['Bristol',  510],
    ['Wiltshire',  525],
    ['Somerset',  530],
    ['Dorset',  740],
    ['Devon',  740],
    ['Cornwall',  750],
    ['Dumfriesshire',  600],
    ['Scottish Borders',  710],
    ['South Ayrshire',  630],
    ['East Ayrshire',  545],
    ['Midlothian',  600],
    ['East Lothian',  615],
    ['Edinburgh', 615],
    ['West Lothian',  600],
    ['East Renfrewshire',  590],
    ['Glasgow', 580],
    ['North Ayrshire',  580],
    ['Falkirk',  580],
    ['Renfrewshire',  590],
    ['East Dunbartonshire',  590],
    ['Fife',  625],
    ['Clackmannanshire',  590],
    ['West Dunbartonshire',  590],
    ['Stirling',  590],
    ['Inverclyde',  610],
    ['Perth and Kinross',  730],
    ['Argyll and Bute',  700],
    ['Angus',  700],
    ['Aberdeen',  790],
    ['Aberdeenshire',  800],
    ['Moray',  820],
    ['Highland',  820],
];
