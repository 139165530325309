export default [
    {
        label: 'North West',
        options: ['Greater Manchester', 'Cheshire', 'Merseyside', 'Lancashire', 'Cumbria']
    },
    {
        label: 'Yorkshire and the Humber',
        options: ['West Yorkshire', 'South Yorkshire', 'East Yorkshire', 'North Yorkshire']
    },
    {
        label: 'East Midlands',
        options: ['Derbyshire', 'Nottinghamshire', 'Leicestershire', 'Lincolnshire', 'Norhamptonshire']
    },
    {
        label: 'West Midlands',
        options: ['Staffordshire', 'Shropshire', 'Worcestershire', 'Warwickshire', 'Herefordshire']
    },
    {
        label: 'Wales',
        options: ['Wrexham', 'Flintshire','Denbighshire', 'Conwy', 'Gwynedd', 'Anglesey', 'Powys', 'Ceredigion', 'Monmouthshire', 'Blaenau Gwent', 'Tydfil', 'Torfaen', 'Newport', 'Rhondda Cynon Taf', 'Caerphilly', 'Neath Port Talbot', 'Carmarthenshire', 'Cardiff', 'Bridgend', 'Swansea', 'Pembrokeshire']
    },
    {
        label: 'North East',
        options: ['Durham', 'Newcastle upon Tyne', 'Northumberland']
    },
    {
        label: 'East of England',
        options: ['Bedfordshire', 'Cambridgeshire', 'Hertfordshire', 'Norfolk', 'Suffolk']
    },
    {
        label: 'South East',
        options: ['Oxfordshire', 'Buckinghamshire', 'Berkshire', 'London', 'Essex', 'Surrey', 'Hampshire', 'Kent', 'West Sussex', 'East Sussex']
    },
    {
        label: 'South West',
        options: ['Gloucestershire', 'Bristol', 'Wiltshire', 'Somerset', 'Dorset', 'Devon', 'Cornwall']
    },
    {
        label: 'Scotland',
        options: [ 'Dumfriesshire',
            'Scottish Borders',
            'South Ayrshire',
            'East Ayrshire',
            'Midlothian',
            'East Lothian',
            'West Lothian',
            'East Renfrewshire',
            'North Ayrshire',
            'Falkirk',
            'Renfrewshire',
            'East Dunbartonshire',
            'Fife',
            'Clackmannanshire',
            'West Dunbartonshire',
            'Stirling',
            'Inverclyde',
            'Perth and Kinross',
            'Argyll and Bute',
            'Angus',
            'Aberdeen',
            'Edinburgh',
            'Glasgow',
            'Aberdeenshire',
            'Moray',
            'Highland' ]
    }
];
