<template>
  <div id="app">
<!--    <div id="nav">-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </div>-->

      <b-row>
          <b-col lg="9" xs="12">
              <Header />
              <AvailabilityChecker v-if="showAvailabilityChecker" />
              <router-view/>
          </b-col>
      </b-row>
      <SocialLinks />
  </div>
</template>

<style lang="scss">
body {
    background-color: black;
    background-image: url("~@/assets/sam.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-x: right;
    background-position-y: top;
    -webkit-background-size: 45%;
    background-size: 45%;
}

@media only screen and (max-width: 800px) {
    body {
        background-image: none;
    }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 50px;
    max-width: 1200px;
    color: white;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import SocialLinks from "@/components/SocialLinks";
import AvailabilityChecker from "@/components/AvailabilityChecker";
import Header from "@/components/Header";
export default {
    components: {Header, SocialLinks, AvailabilityChecker},
    computed: {
        showAvailabilityChecker() {
            return this.$route.name !== 'Submit Review';
        }
    }
}
</script>
