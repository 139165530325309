import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Reviews from '../views/Reviews';
import BookingEnquiry from "@/views/BookingEnquiry";
import SubmitReview from "@/views/SubmitReview";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reviews',
    name: 'Reviews',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Reviews
  },
  {
    path: '/booking',
    name: 'Booking Enquiry',
    component: BookingEnquiry,
  },
  {
    path: '/videos',
    beforeEnter() {location.href = 'https://www.youtube.com/channel/UCTTr4xVHXXMJAH5aI6eHuuQ/videos'}
  },
  {
    path: '/submit-review',
    name: 'Submit Review',
    component: SubmitReview,
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
