<template>
    <div class="availability-checker">
        <h3>Check Availability Instantly</h3>

        <b-form @submit.prevent="calculateBooking">
            <b-row>
                <b-col xs="12" md="3">
                    <b-form-group>
                        <label for="selectEventType">Event Type</label>
                        <b-form-select required v-model="selectedEventType" id="selectEventType" size="sm" :options="eventTypesOptions">
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" md="3">
                    <b-form-group class="form-group">
                        <label for="selectLocation">Location</label>
                        <b-form-select required name="location" v-model="selectedEventLocation" :options="eventLocationsOptions" id="selectLocation" size="sm" >
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col xs="12" md="4">
                    <label for="eventDate">Date</label>
                    <b-form-datepicker size="sm" if="eventDate" :min="minDate" v-model="selectedEventDate" />
                </b-col>

                <b-col xs="12" md="2">
                    <div class="form-group">
                        <label></label>
                        <b-button type="submit" class="mt-2" variant="primary" size="sm" block>Check Now</b-button>
                    </div>
                </b-col>

            </b-row>


        </b-form>
    </div>
</template>

<script>
import eventTypes from "@/assets/data/event-types";
import eventLocations from "@/assets/data/event-locations";

export default {
    name: "AvailabilityChecker",
    data() {
        return {
            eventTypes,
            eventLocations,
            selectedEventType: null,
            selectedEventLocation: null,
            selectedEventDate: null,
            minDate: new Date(),
        }
    },
    computed: {
      eventTypesOptions() {
          return [{value: null, text: 'Please select an option', disabled: true}, ...eventTypes];
      },
        eventLocationsOptions() {
          return [{value: null, text: 'Please select an option', disabled: true}, ...eventLocations];
        }
    },
    methods: {
        calculateBooking() {
            this.$router.push({name: 'Booking Enquiry', query: {eventType: this.selectedEventType, eventLocation: this.selectedEventLocation, eventDate: this.selectedEventDate}})
        }
    },
    created() {
        this.eventLocations.sort((o1, o2) => o1.label.localeCompare(o2.label));
        this.eventLocations.forEach((loc) => {
            loc.options.sort();
        })
    }
}
</script>

<style scoped>
div.availability-checker {
    margin-top: 25px;
    padding: 10px 20px;
    background-color: #555555;
}
</style>
