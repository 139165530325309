<template>
  <div class="home">
      <MenuItem class="menu" to="/videos" target="_blank">Videos</MenuItem>
      <MenuItem class="menu" to="/reviews">Reviews</MenuItem>
  </div>
</template>

<script>
import MenuItem from '../components/MenuItem';

export default {
  name: 'Home',
  components: {
      MenuItem
  }
}
</script>


<style scoped>
div.home {
    margin-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

.menu {
    margin-top: 50px;
}
</style>
