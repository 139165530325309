<template>
    <div class="booking-enquiry">
        <div style="width: 100%; text-align: right;">
            <router-link to="/">
                <b-icon-x-lg></b-icon-x-lg>
            </router-link>
        </div>
        <h2>£{{price}}*</h2>
        <h3>For your {{eventType}} on {{formattedDate}} in {{eventLocation}}.</h3>
        <p>Price based on Huzi performing up to 2 hours of close up magic.</p>
        <p>* Availability and Price estimates are <b>approximate</b>.</p>
        <h2>To confirm booking, please contact Huzi @ </h2>
        <a href="mailto:huzimagic@gmail.com"><h2>huzimagic@gmail.com</h2></a>
    </div>
</template>

<script>
import priceList from "@/assets/data/price-list";

export default {
    name: "BookingEnquiry",
    data() {
        return {
            eventType: '',
            eventDate: '',
            eventLocation: '',
        }
    },
    computed: {
        price() {
            let p = priceList.find(el => el[0] === this.eventLocation);
            if (!p) {
                return 'POA';
            }
            return p[1];
        },
        formattedDate() {
           return new Date(this.eventDate).toLocaleDateString('en-GB');
        }
    },
    beforeRouteEnter($to, _, next) {
        if (!$to.query.eventType || !$to.query.eventLocation || !$to.query.eventDate) {
           this.$router.push({name: 'Home'});
        } else {
            next(vm => {
                vm.eventType = $to.query.eventType;
                vm.eventLocation = $to.query.eventLocation;
                vm.eventDate = $to.query.eventDate;
            });
        }
    },
    beforeRouteUpdate($to) {
        this.eventType = $to.query.eventType;
        this.eventLocation = $to.query.eventLocation;
        this.eventDate = $to.query.eventDate;
    }
}
</script>

<style scoped>
div.booking-enquiry {
    background-color: #555555;
    padding: 10px 20px;
    margin-top: 25px;
    text-align: center;
}
a {
    color: white;
}
</style>
