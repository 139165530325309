<template>
    <div style="width: 100%; text-align: center;">
        <router-link  :to="to" :target="target"><slot></slot></router-link>
    </div>
</template>

<script>
export default {
    name: "MenuItem",
    props: {
        to: {
            type: String,
            required: false,
        },
        target: {
            type: String,
            required: false,
            default: '_self'
        }
    }
}
</script>

<style scoped>
 a {
    color: white;
     text-transform: uppercase;
     letter-spacing: 6px;
     font-weight:lighter;
     font-size: 1.2em;
}

 a:hover {
    text-decoration: none;
    font-weight:bold;
}
</style>
