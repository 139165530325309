import Vue from 'vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {
    BRow,
    BCol,
    BForm,
    BFormSelect,
    BFormGroup,
    BButton,
    BFormDatepicker,
    BIconStarFill,
    BFormTextarea, BFormRating, BFormInput, BAlert
} from "bootstrap-vue";
import { BIconArrowLeft, BIconArrowRight, BIconXLg } from "bootstrap-vue";

Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.component('b-form', BForm);
Vue.component('b-form-select', BFormSelect);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-form-datepicker', BFormDatepicker);
Vue.component('b-form-textarea', BFormTextarea);
Vue.component('b-form-rating', BFormRating);
Vue.component('b-form-input', BFormInput);
Vue.component('b-alert', BAlert);
Vue.component('b-button', BButton);

Vue.component('b-icon-arrow-left', BIconArrowLeft);
Vue.component('b-icon-arrow-right', BIconArrowRight);
Vue.component('b-icon-x-lg', BIconXLg);
Vue.component('b-icon-star-fill', BIconStarFill);
