<template>
    <div>
        <div class="social hidden-mobile">
            <a href="https://www.instagram.com/huzimagician/" target="_blank"><img src="@/assets/icons/instagram.png" style="width: 40px;" /></a>
        </div>

        <div class="col-xs-12 hidden-desktop social-mobile">
            <a href="https://www.instagram.com/huzimagician/" target="_blank"><img src="@/assets/icons/instagram.png" style="width: 40px;" /></a>
        </div>
    </div>
</template>

<script>
export default {
    name: "SocialLinks"
}
</script>

<style scoped>
div.social {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 50px;
    margin-right: 50px;
}

.hidden-mobile {
    display:block;
}
.hidden-desktop {
    display: none;
}

@media only screen and (max-width: 800px) {
    div.social-mobile {
        text-align:center;
        width: 100%;
        margin-top: 50px;
    }
    div.social-mobile a {
        margin-left: 10px;
        margin-right: 10px;
    }
    .hidden-mobile {
        display:none;
    }
    .hidden-desktop {
        display: block;
    }
}

</style>
