<template>
    <div class="submit-review">

        <h2>Leave your review</h2>
        <b-form v-if="!submitted" @submit.prevent="submit">

            <b-alert show variant="danger" v-if="error">
                {{error}}
            </b-alert>

            <b-form-group>
                <label>Name</label>
                <b-form-input v-model="request.name" />
            </b-form-group>

            <b-form-group>
                <label>Rating</label>
                <b-form-rating variant="dark" v-model="request.rating"/>
            </b-form-group>

            <b-form-group>
                <label>Comments</label>
                <b-form-textarea v-model="request.comments" />
            </b-form-group>
            <div style="width: 100%; text-align: center;">
                <b-button variant="primary" type="submit" class="ml-auto mr-auto" :disabled="!canSubmit">Submit Review</b-button>

            </div>
        </b-form>

        <b-alert show variant="success" v-if="submitted" class="mt-4">
            Thanks for your review.
        </b-alert>


    </div>
</template>

<script>
import { load } from 'recaptcha-v3'

const GOOGLE_SITE_KEY = '6Ld7pUYgAAAAAApmTd6UL5q8lpYj_dbfDRb9bw3R';

export default {
    name: "SubmitReview",
    data() {
        return {
            submitted: false,
            loading: false,
            error: null,
            request: {
                name: '',
                comments: '',
                rating: null,
            }
        }
    },
    computed: {
        canSubmit() {
            return this.request.name !== '' && this.request.comments !== '' && this.request.rating != null
        }
    },
    methods: {
        submit() {
            this.error = null;
            this.loading = false;
            load(GOOGLE_SITE_KEY).then((recaptcha) => {
                recaptcha.execute('submit_review').then((token) => {
                    console.log(token);
                    this.$http.post('/api/add_review.php', {
                        recaptcha_token: token,
                        ...this.request
                    }).then((resp) => {
                        resp.data.message;
                        this.submitted = true;
                    }).catch((resp) => {
                        if (resp.data.message) {
                            this.error = resp.data.message;
                        } else {
                            this.error = 'Failed to submit review. Please try again later.';
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                })
            });

            // this.submitted = true;
        }
    }
}
</script>

<style scoped>
.submit-review {
    background-color: #555555;
    padding: 10px 20px;
    margin-top: 25px;
    /*text-align: center;*/
}
</style>
