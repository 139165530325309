<template>
    <div class="header">
        <router-link to="/"><div class="logo"></div></router-link>
    </div>
</template>

<script>
export default {
    name: "Header"
}
</script>

<style scoped>
div.logo {
    margin-left:auto;
    margin-right: auto;
    background: url("~@/assets/huzi.jpg") no-repeat;
    background-size:contain;
    background-position: center;
    width: 100%;
    height: 220px;
}

@media only screen and (max-width: 800px) {
    div.logo {
        width: 100%;
        height:100px;
        margin-left: auto;
        margin-right: auto;
        background-position: center;
    }
}


</style>
