<template>
    <div class="reviews" ref="top">
        <div style="width: 100%; text-align: right;">
            <router-link to="/">
                <b-icon-x-lg></b-icon-x-lg>
            </router-link>
        </div>
        <h2>Reviews</h2>
        <b-spinner label="Loading" v-if="loading" variant="light"></b-spinner>
        <ReviewItem v-for="review in reviewsThisPage" v-bind:key="review.id" :review="review"></ReviewItem>

        <b-row class="review-controls">
            <b-col xs="6" style="text-align:left;">
                <a @click="previousPage" v-if="hasPreviousPage"><b-icon-arrow-left /> Previous Page</a>
            </b-col>
            <b-col xs="6" style="text-align: right;">
                <a @click="nextPage" v-if="hasNextPage">Next Page <b-icon-arrow-right /></a>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ReviewItem from "@/components/ReviewItem";
export default {
    name: "Reviews",
    components: {ReviewItem},
    data() {
        return {
            reviews: [],
            loading: false,
            currentPage: 1,
            perPage: 5,
        }
    },
    computed: {
        hasNextPage() {
            // check this
            return this.currentPage < Math.ceil(this.reviews.length / this.perPage);
        },
        hasPreviousPage() {
            return this.currentPage > 1;
        },
        reviewsThisPage() {
            let start = (this.currentPage - 1) * this.perPage;
            let end = start + this.perPage;
            if (end > this.reviews.length) {
                end = this.reviews.length - 1;
            }
            return this.reviews.slice(start, end);
        }
    },
    methods: {
        loadReviews() {
            this.loading = true;
            this.$http.get('https://huzimagician.co.uk/api/reviews.php').then((resp) => {
                this.reviews = resp.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        nextPage() {
            this.currentPage++;
            this.scrollToTop();
        },
        previousPage() {
            this.currentPage--;
            this.scrollToTop();
        },
        scrollToTop() {
            let el = this.$refs.top;
            if (el) {
                el.scrollIntoView({behavior: "smooth"});
            }
        }
    },
    created() {
        this.loadReviews();
    }
}
</script>

<style scoped>
div.reviews {
    background-color: #555555;
    padding: 10px 20px;
    margin-top: 25px;
    text-align: center;
}
a {
    color: white;
}
.review-controls a {
    cursor: pointer;
}
</style>
